jQuery(function($) {

  /* initialize FlexNav */

  $(".flexnav").flexNav({
    'calcItemWidths': false,
    'hover': false
  });

  /* prevent default for parent links */

  $(".flexnav").find('li.menu-item-has-children > a').on('click', function(e) {
    e.preventDefault();
  });

  /* match Height */

  $('.services a').matchHeight();
  $('.servicesTwo a').matchHeight();
  $('footer .col').matchHeight();

  /* hunt */

  if ($('.timeline li, .services li, .member, .partner a').length) {
    $('.timeline li, .services li, .member, .partner a').addClass('hide');
    hunt(document.querySelectorAll('.timeline li, .services li, .member, .partner a'), {
      in: function() {
        this.classList.remove('hide');
        this.classList.add('visible');
      },
      offset: -100
    });
  }

});